import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { catchError, of } from 'rxjs';

import { TFormattedApiError } from '@utils/formattingApiError';
import { TCategory, TMainPageCategories, TRegion } from '@models/commonTypes';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class ReferencesService {
  constructor(private http: HttpClient) {}

  // Регионы
  #regionsList = signal<TRegion[] | null>([]);
  regionsList = computed(this.#regionsList);

  // Категории
  #categoriesList = signal<TCategory[]>([]);
  categoriesList = computed(this.#categoriesList);

  // Категории главной страницы
  #mainPageCategoriesList = signal<TMainPageCategories | null>(null);
  mainPageCategoriesList = computed(this.#mainPageCategoriesList);

  loadAllReferences(): void {
    this.loadRegionsList();
    this.loadCategoriesList();
    this.loadMainPageCategoriesList();
  }

  loadRegionsList(): void {
    this.http
      .get<TRegion[]>(`${environment.getApiVersionUrl('references/regions')}`, {
        headers: {
          skipBearerAuthorization: 'true',
        },
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);

          return of(null);
        }),
      )
      .subscribe((response) => {
        this.#regionsList.set(response);
      });
  }

  loadCategoriesList(): void {
    this.http
      .get<TCategory[]>(
        `${environment.getApiVersionUrl('references/categories')}`,
        {
          headers: {
            skipBearerAuthorization: 'true',
          },
        },
      )
      .subscribe({
        next: (response) => this.#categoriesList.set(response),
        error: (error: TFormattedApiError) => {
          console.error(error);

          return of(null);
        },
      });
  }

  loadMainPageCategoriesList(): void {
    this.http
      .get<TMainPageCategories>(
        `${environment.getApiVersionUrl('references/main-page-categories')}`,
        {
          headers: {
            skipBearerAuthorization: 'true',
          },
        },
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(error);

          return of(null);
        }),
      )
      .subscribe((response) => {
        this.#mainPageCategoriesList.set(response);
      });
  }
}

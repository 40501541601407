<div
  class="search-input-wrapper"
  [class.drawer]="variety() === 'drawer'"
  [class.priority]="variety() === 'priority'"
  [class.select-search]="variety() === 'select'"
>
  <input
    type="search"
    inputmode="search"
    [placeholder]="placeholder()"
    [ngClass]="{
      'search-input': true,
      light: variety() === 'light',
      dark: variety() === 'dark',
      drawer: variety() === 'drawer',
      priority: variety() === 'priority',
      'select-search': variety() === 'select',
    }"
    [formControl]="control"
  />
  @if (!control.value) {
    <button
      type="button"
      [ngClass]="{
        'search-button': true,
        light: variety() === 'light',
        dark: variety() === 'dark',
        drawer: variety() === 'drawer',
        priority: variety() === 'priority',
        'select-search': variety() === 'select',
        disabled: true,
      }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
          [attr.stroke]="iconSearchColor()"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21 21L16.7 16.7"
          [attr.stroke]="iconSearchColor()"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  } @else {
    <button
      type="button"
      (click)="clear()"
      [ngClass]="{
        'search-button': true,
        light: variety() === 'light',
        dark: variety() === 'dark',
        drawer: variety() === 'drawer' || variety() === 'priority',
        'select-search': variety() === 'select',
      }"
    >
      <mat-icon>close</mat-icon>
    </button>
  }
</div>

<button
  (click)="pressedFunction()"
  [type]="type()"
  [disabled]="disabled()"
  [ngClass]="{
    'icon-button': true,
    white: variety() === 'white',
    light: variety() === 'light',
    dark: variety() === 'dark',
    favorite: variety() === 'favorite',
    small: smallSize(),
  }"
>
  @switch (kind()) {
    @case ("favorite") {
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        class="favorite-button"
      >
        <path
          d="M14.25 10.5C15.3675 9.405 16.5 8.0925 16.5 6.375C16.5 5.28098 16.0654 4.23177 15.2918 3.45818C14.5182 2.6846 13.469 2.25 12.375 2.25C11.055 2.25 10.125 2.625 9 3.75C7.875 2.625 6.945 2.25 5.625 2.25C4.53098 2.25 3.48177 2.6846 2.70818 3.45818C1.9346 4.23177 1.5 5.28098 1.5 6.375C1.5 8.1 2.625 9.4125 3.75 10.5L9 15.75L14.25 10.5Z"
          stroke="#050609"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    }
    @case ("up") {
      <app-up-icon stroke="#ffffff"></app-up-icon>
    }
  }
</button>

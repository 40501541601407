import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, computed, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UIService {
  #removeMainScroll = signal<boolean>(false);
  removeMainScroll = computed(this.#removeMainScroll);

  // Переключение на мобильные header и footer
  #isMobile = signal<boolean>(false);
  isMobile = computed(this.#isMobile);

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe(['(max-width: 900px)'])
      .subscribe((state: BreakpointState) => {
        this.#isMobile.set(state.breakpoints['(max-width: 900px)']);
      });
  }

  toggleMainScroll(): void {
    this.#removeMainScroll.update((prev) => !prev);
  }

  resetMainScroll(): void {
    this.#removeMainScroll.set(false);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Observable } from 'rxjs';

import {
  TAnnouncement,
  TAnnouncementCreate,
  TAnnouncementDraftCreate,
} from '@models/announcementTypes';
import { TFormattedApiError } from '@utils/formattingApiError';
import { TCereal, TClassification } from '@models/commonTypes';
import { TBooleanResponse } from '@models/authTypes';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AnnouncementService {
  #announcement = signal<TAnnouncement | null>(null);
  announcement = computed(this.#announcement);

  #loading = signal<boolean>(false);
  loading = computed(this.#loading);

  #loadingList = signal<boolean>(false);
  loadingList = computed(this.#loadingList);

  #error = signal<string | null>(null);
  error = computed(this.#error);

  #cereals = signal<TCereal[]>([]);
  cereals = computed(this.#cereals);

  #cerealsMap = signal<Map<string, TCereal[]>>(new Map<string, TCereal[]>());
  cerealsMap = computed(this.#cerealsMap);

  #classifications = signal<TClassification[]>([]);
  classifications = computed(this.#classifications);

  constructor(private http: HttpClient) {}

  loadAnnouncementUnauth(slug: string): Observable<TAnnouncement> {
    return this.http.get<TAnnouncement>(
      `${environment.getApiVersionUrl(`announcements/${slug}`)}`,
      {
        headers: {
          skipBearerAuthorization: 'true',
        },
      },
    );
  }

  loadAnnouncementWithStats(slug: string): Observable<TAnnouncement> {
    return this.http.get<TAnnouncement>(
      `${environment.getApiVersionUrl(`announcements/${slug}`)}`,
    );
  }

  // Получение списка культур
  loadCereals(category_code: string) {
    const cachedCereals = this.#cerealsMap().get(category_code);
    if (cachedCereals) {
      // уже всё загружено, возьмём из кэша
      this.#cereals.set(cachedCereals);
      return;
    }

    this.#error.set(null);
    this.#loadingList.set(true);

    this.http
      .get<TCereal[]>(
        `${environment.getApiVersionUrl(`references/categories/${category_code}/cereals`)}`,
        {
          headers: {
            skipBearerAuthorization: 'true',
          },
        },
      )
      .subscribe({
        next: (response) => {
          this.#cereals.set(response);
          this.#cerealsMap().set(category_code, response);
          this.#cerealsMap.update((currentMap) => new Map(currentMap));
          this.#loadingList.set(false);
        },

        error: (error: TFormattedApiError) => {
          this.#loadingList.set(false);
          // Если нет списка культур, то нет и списка классификаций
          this.#cereals.set([]);
          this.#cerealsMap().set(category_code, []);
          this.#cerealsMap.update((currentMap) => new Map(currentMap));
          this.#classifications.set([]);

          console.error(error);
        },
      });
  }

  // Получение списка классов
  loadClassifications(category_code: string, cereal_id: number) {
    this.#error.set(null);
    this.#loadingList.set(true);

    this.http
      .get<TClassification[]>(
        `${environment.getApiVersionUrl(`references/categories/${category_code}/cereals/${cereal_id}/classifications`)}`,
        {
          headers: {
            skipBearerAuthorization: 'true',
          },
        },
      )
      .subscribe({
        next: (response) => {
          this.#classifications.set(response);
          this.#loadingList.set(false);
        },
        error: (error: TFormattedApiError) => {
          this.#loadingList.set(false);
          this.#classifications.set([]);

          console.error(error);
        },
      });
  }

  // Сохранить черновик объявления
  saveAnnouncementDraft(
    data: TAnnouncementDraftCreate,
  ): Observable<TAnnouncement> {
    this.#loading.set(true);
    this.#error.set(null);

    return this.http.post<TAnnouncement>(
      `${environment.getApiVersionUrl('announcements/my/draft')}`,
      { ...data },
    );
  }

  // Создать объявление
  saveAnnouncement(data: TAnnouncementCreate): Observable<TAnnouncement> {
    this.#loading.set(true);
    this.#error.set(null);

    return this.http.post<TAnnouncement>(
      `${environment.getApiVersionUrl('announcements/my/create')}`,
      { ...data },
    );
  }

  // Редактирование объявления
  editAnnouncement(
    data: TAnnouncementCreate,
    slug: string,
  ): Observable<TAnnouncement> {
    this.#loading.set(true);
    this.#error.set(null);

    return this.http.put<TAnnouncement>(
      `${environment.getApiVersionUrl(`announcements/my/${slug}`)}`,
      { ...data },
    );
  }

  // Снятие объявления с публикации
  disabledAnnouncement(slug: string): Observable<TBooleanResponse> {
    this.#error.set(null);

    return this.http.put<TBooleanResponse>(
      `${environment.getApiVersionUrl(`announcements/my/${slug}/disable`)}`,
      {},
    );
  }

  // Изменение цены объявления
  updatePrice(slug: string, price: number): Observable<TAnnouncement> {
    return this.http.put<TAnnouncement>(
      `${environment.getApiVersionUrl(`announcements/my/${slug}/price`)}`,
      {
        price,
      },
    );
  }

  // Публикация объявления
  activeAnnouncement(slug: string): Observable<TBooleanResponse> {
    this.#error.set(null);

    return this.http.put<TBooleanResponse>(
      `${environment.getApiVersionUrl(`announcements/my/${slug}/active`)}`,
      {},
    );
  }

  // Удаление объявления
  deleteAnnouncement(slug: string): Observable<TBooleanResponse> {
    this.#error.set(null);

    return this.http.delete<TBooleanResponse>(
      `${environment.getApiVersionUrl(`announcements/my/${slug}`)}`,
    );
  }

  setLoading(newValue: boolean) {
    this.#loading.set(newValue);
  }

  setError(newValue: string | null) {
    this.#error.set(newValue);
  }

  getCerealForCode(
    categoryCode: string,
    cerealId?: number,
  ): TCereal | undefined {
    return this.cerealsMap()
      .get(categoryCode)
      ?.find((cereal) => cereal.id == cerealId);
  }
}

import { Component, input, output } from '@angular/core';
import { NgClass } from '@angular/common';

import { UpIconComponent } from '../icons/up-icon.component';
import { SearchIconComponent } from '../icons/search-icon.component';

export type TIconButtonKind = 'favorite' | 'up';

@Component({
  selector: 'app-icon-button',
  standalone: true,
  imports: [NgClass, UpIconComponent, SearchIconComponent],
  templateUrl: './icon-button.component.html',
  styleUrl: './icon-button.component.scss',
})
export class IconButtonComponent {
  disabled = input<boolean>(false);
  variety = input<'white' | 'light' | 'dark' | 'favorite'>('light');
  type = input<'button' | 'submit'>('button');
  kind = input.required<TIconButtonKind>();
  smallSize = input<boolean>(false);

  pressed = output();
  pressedFunction() {
    this.pressed.emit();
  }
}

<button
  (click)="pressedFunction()"
  [type]="type()"
  [disabled]="disabled()"
  [ngClass]="{
    button: true,
    primary: variety() === 'primary',
    secondary: variety() === 'secondary',
    grey: variety() === 'grey',
    white: variety() === 'white',
    transparent: variety() === 'transparent',
    'only-text': variety() === 'only-text',
    stretch: width() === 'stretch',
    withIcon: withIcon(),
    withSvg: withSvg(),
    'narrow-padding': narrowPadding(),
  }"
>
  <ng-content></ng-content>
</button>

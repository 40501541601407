export const environment = {
  apiUrl: 'https://api.zackroma.y.freematiq.com',
  apiVersion: 'v1',

  client_id: 'testclient',
  client_secret: 'testpass',

  captcha_key: '6LdYMv4pAAAAAPXknf7cTuhGAZkEdRUnK9OaZV-Q',

  getApiVersionUrl(path: string) {
    return `${this.apiUrl}/${this.apiVersion}/${path}`;
  },

  getRequestData() {
    return {
      client_id: this.client_id,
      client_secret: this.client_secret,
    };
  },

  production: true,
};

import { Component, input, output } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [NgClass],
  host: {
    '[style.height]': 'height() + "px"',
  },
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  disabled = input<boolean>(false);
  withIcon = input<boolean>(false);
  withSvg = input<boolean>(false);
  variety = input<
    'primary' | 'secondary' | 'grey' | 'only-text' | 'transparent' | 'white'
  >('primary');
  type = input<'button' | 'submit'>('button');
  width = input<'max-content' | 'stretch'>('max-content');
  height = input<number>(46);
  narrowPadding = input<boolean>(false);

  pressed = output();
  pressedFunction() {
    this.pressed.emit();
  }
}

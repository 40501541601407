import { NgClass } from '@angular/common';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  Component,
  DestroyRef,
  OnInit,
  computed,
  inject,
  input,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { debounceTime } from 'rxjs';

import { MatIcon } from '@angular/material/icon';

import { IconButtonComponent } from '../icon-button/icon-button.component';
@Component({
  selector: 'app-search',
  standalone: true,
  imports: [NgClass, ReactiveFormsModule, IconButtonComponent, MatIcon],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
})
export class SearchComponent implements OnInit {
  variety = input<'light' | 'dark' | 'drawer' | 'select' | 'priority'>('light');
  placeholder = input<string>('Введите значение');

  iconSearchColor = computed(() => {
    return this.variety() === 'dark' ? '#ffffff' : '#050609';
  });

  value = '';
  readonly control = new FormControl<string>(this.value, {
    nonNullable: true,
    validators: [Validators.maxLength(255)],
  });

  searchText = output<string>();

  destroyRef = inject(DestroyRef);

  clear() {
    this.control.setValue('');
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.searchText.emit(value);
      });
  }
}
